import { useInView } from 'react-intersection-observer'

export const useImpressionTracker = (analytics, itemsShown) => {
  const activeIndex = React.useRef(0)
  const viewedIndexes = React.useRef([])
  const viewedContainer = React.useRef(false)

  const [inViewRef, isInView] = useInView({ threshold: 0.75 })

  React.useEffect(() => {
    if (isInView) {
      if (analytics?.carousel?.action && !viewedContainer.current) {
        obe.analytics.track(analytics?.carousel?.action, analytics?.carousel?.tracking)
        viewedContainer.current = true
      }

      if (analytics?.items?.action) {
        fireImpressionEvents(activeIndex.current, itemsShown, viewedIndexes, analytics)
      }
    }
  }, [isInView])

  const handleActiveIndexChange = (realIndex) => {
    activeIndex.current = realIndex

    if (analytics?.items?.action && isInView) {
      fireImpressionEvents(realIndex, itemsShown, viewedIndexes, analytics)
    }
  }

  return {
    handleActiveIndexChange,
    inViewRef,
  }
}

const fireImpressionEvents = (realIndex, itemsShown, viewedIndexesRef, analytics) => {
  for (let index = realIndex; index < realIndex + itemsShown; index++) {
    if (!viewedIndexesRef.current.includes(index)) {
      obe.analytics.track(analytics?.items?.action, {
        ...(analytics?.carousel?.tracking || {}),
        ...analytics?.items?.tracking?.[index],
      })
    }
    viewedIndexesRef.current = [...viewedIndexesRef.current, index]
  }
}
