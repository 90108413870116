import { RichText } from 'src/components/Display/RichText'
import { breakpoint } from 'src/styles'

type HeaderProps = {
  title: string
  titleDecoration?: React.ReactElement
  description: string
  itemPadding?: string
  showNewIndicator?: boolean
}

export const Header = React.memo<HeaderProps>(
  ({ description, itemPadding, showNewIndicator, title, titleDecoration }) => (
    <Styles.Header compact centraBook itemPadding={itemPadding}>
      <h2>
        {title}
        {showNewIndicator && <Styles.NewIndicator />}
        {titleDecoration && <Styles.Decoration>{titleDecoration}</Styles.Decoration>}
      </h2>
      <div>{description}</div>
    </Styles.Header>
  )
)

const Styles = {
  Header: styled(RichText.Medium)<{ itemPadding?: string }>`
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0;
    margin-bottom: 24px;
    padding: 0;
    user-select: none;

    h2 {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 10px;
      margin-top: 0;
      font-weight: 200;
    }
    ${breakpoint.mediumAndDown} {
      font-size: 16px;
      line-height: 24px;
      h2 {
        font-size: 24px;
      }
    }

    ${({ itemPadding }) =>
      itemPadding &&
      css`
        padding: ${itemPadding};
      `}
  `,
  NewIndicator: styled.div`
    color: var(--nearBlack);
    display: inline-block;
    position: relative;
    left: 4px;
    top: -12px;

    ::after {
      content: 'New!';
      background-color: var(--yellow);
      text-align: center;
      font-size: 10px;
      font-weight: normal;
      padding: 5px;
      line-height: 0;
    }
  `,
  Decoration: styled.div`
    display: inline-block;
    margin-left: 12px;
  `,
}
