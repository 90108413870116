import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules'
import { isMobile, isTablet } from 'react-device-detect'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Arrows } from './Arrows'
import { copy } from 'src/styles'
import { Header } from './Header'
import { isNotOffScreen } from 'src/utils/boundingClientRect'
import { useImpressionTracker } from './useImpressionTracker'
import { useResponsive } from 'src/hooks/useResponsive'

export const SwiperCarousel = ({
  analytics = {},
  carouselName = 'carousel',
  centeredSlides = false,
  children,
  className = 'carousel',
  description = '',
  itemPadding,
  itemsShown = 1,
  loop = true,
  onSlideClick = Function.prototype,
  settings = {},
  showArrows = true,
  showDots = false,
  showHeader = true,
  showNewIndicator = false,
  spaceBetween = 10,
  spaceBetweenArrows = true,
  swiperRef,
  title,
  titleDecoration = null,
  ...props
}) => {
  const { isMediumAndUp } = useResponsive()
  const allowTouchMove = isMobile || isTablet || props.allowTouchMove
  const activeSlideRef = React.useRef(null)
  const isUserInteraction = React.useRef(false)

  const handleSwipeEvent = (direction) => {
    if (typeof props.handleSwipeEvent === 'function') {
      props.handleSwipeEvent(direction)
    } else if (isUserInteraction.current) {
      isUserInteraction.current = false

      obe.analytics.track(`Swiped Carousel ${direction}`, {
        carouselName: analytics?.carousel?.tracking?.carouselName || title,
      })
    }
  }

  const onInitHandler = (swiper) => {
    if (swiperRef) swiperRef.current = swiper
    else if (showDots) swiper.activeIndex = 1
    else return
  }

  const { handleActiveIndexChange, inViewRef } = useImpressionTracker(analytics, itemsShown)

  const containerProps = {
    centeredSlides,
    className,
    itemsShown,
    ref: inViewRef,
    showDots,
  }

  const carouselProps = {
    modules: [Autoplay, Navigation, Pagination, Scrollbar],
    allowTouchMove,
    loop,
    navigation: {
      prevEl: `.prev-${carouselName}`,
      nextEl: `.next-${carouselName}`,
    },
    onActiveIndexChange: ({ realIndex }) => handleActiveIndexChange(realIndex),
    onInit: (swiper) => onInitHandler(swiper),
    onSlideNextTransitionEnd: () => handleSwipeEvent('Left'),
    onSlidePrevTransitionEnd: () => handleSwipeEvent('Right'),
    onTouchStart: () => (isUserInteraction.current = true),
    slidesPerView: itemsShown,
    spaceBetween,
    ...(showDots && { pagination: { clickable: true } }),
    ...settings,
  }

  if (carouselName === 'hero') {
    carouselProps.onSlideChange = () => {
      if (activeSlideRef?.current) {
        activeSlideRef?.current?.classList.remove('active-key')
        props.setActiveKey(null)
      }
    }
  }

  return (
    <Styles.Container {...containerProps}>
      {showHeader &&
        React.createElement(Header, {
          className,
          description,
          itemPadding,
          showNewIndicator,
          title,
          titleDecoration,
        })}
      <Swiper {...carouselProps}>
        {React.Children.map(children, (child, index) => {
          return (
            <SwiperSlide
              onClick={({ currentTarget }) => onSlideClick(currentTarget, activeSlideRef, index)}
            >
              {carouselName === 'hero'
                ? React.cloneElement(child, {
                    _itemWidth: props.itemWidth,
                    activeKey: props.activeKey,
                    activeSlideRef: activeSlideRef.current,
                    isActive:
                      (activeSlideRef?.current && isNotOffScreen(activeSlideRef.current)) ||
                      isMediumAndUp
                        ? index === props.activeKey
                        : false,
                    itemsShown,
                    setWidth: props.setWidth,
                  })
                : child}
            </SwiperSlide>
          )
        })}
        {showArrows && (
          <Arrows
            isChevron={props.isChevron}
            allowTouchMove={allowTouchMove}
            arrowNextClassName={`next-${carouselName}`}
            arrowPrevClassName={`prev-${carouselName}`}
            carouselName={carouselName}
            className={className}
            itemPadding={itemPadding}
            spaceBetweenArrows={spaceBetweenArrows}
            title={analytics?.carousel?.tracking?.carouselName || title}
          />
        )}
      </Swiper>
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    margin-bottom: 32px;
    position: relative;

    .swiper-pagination-horizontal {
      bottom: 5px;
    }

    .swiper-pagination {
      max-width: 500px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: none;

      ${({ showDots }) =>
        showDots &&
        css`
          display: flex;
          justify-content: center;
          position: absolute !important;
        `}
    }

    .swiper-container,
    .swiper-container-initialized,
    .swiper-container-horizontal,
    .swiper-container-pointer-events {
      ${({ showDots }) =>
        showDots &&
        css`
          display: flex;
          align-items: flex-end;
          flex-direction: column-reverse;
        `}
    }

    .swiper-slide,
    .swiper-slide-active {
      ${({ itemsShown }) =>
        itemsShown === 'auto' &&
        css`
          width: 70% !important;
        `}
    }

    .swiper-pagination {
      z-index: 1;
    }

    .swiper-pagination-bullet {
      padding-left: 0;
      margin-right: 10px;
      margin-left: 10px;
    }

    .swiper-pagination-bullet-active {
      margin-right: 5px;
      margin-left: 5px;
      cursor: pointer;
      background-color: #000;
      opacity: 0.5;
      transition: opacity 0.6s ease;
    }

    .swiper-slide {
      height: unset;
    }
  `,
  Title: styled.div`
    ${copy.headerXlarge}
    margin-bottom: 18px;
    margin-top: 0;
    padding: 0 19px;
    font-weight: 200;
  `,

  ArrowContainer: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
}
