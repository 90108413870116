import { Arrow } from 'src/components/Display/Arrow'

interface ArrowsProps {
  allowTouchMove: boolean
  arrowNextClassName: string
  arrowPrevClassName: string
  carouselName: string | undefined
  isChevron: string
  itemPadding: string
  spaceBetweenArrows: string
  title: string
}

export const Arrows: React.FC<ArrowsProps> = React.memo(
  ({
    allowTouchMove,
    arrowNextClassName,
    arrowPrevClassName,
    carouselName,
    isChevron = false,
    itemPadding,
    spaceBetweenArrows,
    title,
  }) => (
    <Styles.Controls
      className='controls'
      carouselName={carouselName}
      itemPadding={itemPadding}
      spaceBetweenArrows={spaceBetweenArrows}
    >
      <Styles.Arrow
        carouselName={carouselName}
        className={arrowPrevClassName}
        onClick={() =>
          obe.analytics.track(
            allowTouchMove ? 'Swiped Carousel Right' : 'Clicked Carousel Left Arrow',
            {
              carouselName: title,
              action: 'video_carousel_prev',
            }
          )
        }
        left
      >
        <Arrow.Left isChevron={isChevron} />
      </Styles.Arrow>
      <Styles.Arrow
        carouselName={carouselName}
        className={arrowNextClassName}
        onClick={() =>
          obe.analytics.track(
            allowTouchMove ? 'Swiped Carousel Left' : 'Clicked Carousel Right Arrow',
            {
              carouselName: title,
              action: 'video_carousel_next',
            }
          )
        }
        right
      >
        <Arrow.Right isChevron={isChevron} />
      </Styles.Arrow>
    </Styles.Controls>
  )
)

const Styles = {
  Controls: styled.div<{ spaceBetweenArrows: string; itemPadding: string }>`
    display: flex;
    width: 100%;
    margin: 1rem 0.25rem;
    height: 30px;

    ${({ spaceBetweenArrows }) =>
      spaceBetweenArrows &&
      css`
        justify-content: space-between;
        > div {
          margin-right: 0;
        }
      `}

    ${({ itemPadding }) =>
      itemPadding &&
      css`
        margin: 1rem ${itemPadding.split(' ').pop()} 0 ${itemPadding.split(' ').pop()};
      `}

      margin-left: 0px;
  `,
  Arrow: styled.div<{ left: string; itemsShown: string; right: string }>`
    width: 30px;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;

    ${({ left }) =>
      left &&
      css`
        left: 40px;
      `}

    ${({ itemsShown }) => css`
      bottom: calc((100vw / ${itemsShown}) * 0.45 + 0px);
    `}

    ${({ right }) =>
      right &&
      css`
        right: 40px;
        margin-right: 40px;
      `}
  `,
}
